@import url('./assets/fonts/heebo/weight-definition.css');

html {
    font-size: 16px;
}

html, body, #root, #root > div {
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Heebo', sans-serif;
    background-color: #252525;
}

::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: rgba(169, 169, 169, 0.6);
    border-radius: 8px;
    opacity: 0.6;
}

.nav-item {
    text-decoration: none;
}
