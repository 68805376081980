@font-face {
    font-family: 'Heebo';
    src: local('Heebo Bold'), local('Heebo-Bold'),
    url('Heebo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Black'), local('Heebo-Black'),
    url('Heebo-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo ExtraLight'), local('Heebo-ExtraLight'),
    url('Heebo-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Light'), local('Heebo-Light'),
    url('Heebo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo ExtraBold'), local('Heebo-ExtraBold'),
    url('Heebo-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Medium'), local('Heebo-Medium'),
    url('Heebo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Thin'), local('Heebo-Thin'),
    url('Heebo-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo SemiBold'), local('Heebo-SemiBold'),
    url('Heebo-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Regular'), local('Heebo-Regular'),
    url('Heebo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

